import React from 'react';
import CategoryGalleryComponentsLibrary from '@mshops-components-library/categories-gallery';
import { useRenderContext } from '../../pages/home/context';

const CategoryGallery = (props) => {
  const { device, isEshops, theme, lowEnd } = useRenderContext();

  return (
    <CategoryGalleryComponentsLibrary
      layoutTheme={theme}
      deviceType={device}
      lowEnd={lowEnd}
      isEshops={isEshops}
      {...props}
    />
  );
};
export default CategoryGallery;
